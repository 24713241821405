.servicesContainer {
	background-color: white;
	border-radius: 5px;
	padding: 50px 20px;
	display: flex;
	flex-direction: column;
	gap: 15px;
	height: fit-content;
}

.servicesContainer h2 {
	margin: 0;
}

.statusRow {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

.statusRow h3 {
	margin: 0;
}