.card{
	min-height: 170px;
	background-color: white;
	box-shadow: 0px 7px 17px #0000000D;
	border-radius: 5px;
	font-size: 15px;
	padding: 15px;
	position: relative;
	display: flex;
	flex-direction: column;
}
.title{
	font-size: 19px;
	font-weight: 700;
	margin-top: 0px;
	display: flex;
	align-items: flex-start;
	margin-left: -5px;
	gap: 10px;
}
.title svg{
	margin-right: 4px;
}
.text{
	margin: 0;
	display: flex;
	align-items: center;
	font-size: 15px;
	margin-bottom: 20px;
}
.textAmount{
	margin: 0 0 10px;
}
.circle{
	position: absolute;
	z-index: 10;
	top: 10px;
	right: 10px;
	width: 20px;
	height: 20px;
	border-radius: 50%;
}
.circle::before{
	position: absolute;
	content: "";
	left: 2px;
	top: 2px;
	width: 16px;
	height: 16px;
	border-radius: 50%;
	border: 2px solid white;
}
.links{
	display: flex;
	font-size: 18px;
	font-weight: bold;
	gap: 20px;
	cursor: pointer;
	width: 45px;
}