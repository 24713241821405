.table-container {
	padding: 10px;
	width: 100%;
	height: auto;
}

.box-image-container {
	width: fit-content;
	height: auto;
	position: relative;
}

.box-image-container > img {
	width: 200px;
	height: 200px
}

.box-image-container > div {
	width: 90px;
	height: 90px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.box-image-container button {
	position: absolute;
	right: -20px;
	top: -25px;
}

.associate-add-footer-container {
	width: 100%;
	display: flex;
	justify-content: flex-end;
}

.associate-add-footer-container > button {
	max-width: 100px;
	margin: 10px;
}

.associate-detail-card {
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;
}

.associate-detail-card > button {
	max-width: 100px;
}

.imageContainer {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 120px;
	height: 120px;
	border-radius: 100px;
}