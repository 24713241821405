.container {
    width: 100px;
    height: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    border: 1px dashed;
}

.container:hover{
    cursor: pointer;
}

.container .buttonText {
    font-size: 14px;
    font-weight: 600;
    padding-left: 10px;
    padding-right: 10px;
    text-align: center;
}