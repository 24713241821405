.boxPaper {
    height: auto;
    width: 100%;
    background-color: #fff;
    box-shadow: 0px 3px 34px #4E4E4E05;
    border-radius: 2px;
}

.days {
    display: flex;
    flex-wrap: wrap;
    gap:20px;
}

.titleContainer{
    padding: 28px 34px;
}

.title {
    color: var(--color-blue-main);
    font-size: 18px;
    font-weight: 600;
}

.container {
    display: flex;
    flex-direction: column;
    padding: 30px 65px;
    gap: 28px;
}


.buttonsContainer{
    display: flex;
    gap: 15px;
    align-self: flex-end;
    justify-content: flex-end;
    padding: 18px 65px;
}

.buttonsContainer button {
    width: 100%;
    max-width: 124px;
    gap: 8px
}

.associatesCardsContainer {
    margin-top: 30px;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    gap: 15px;
}

.associatesCardsModalContainer {
    display: flex;
    flex-flow: row wrap;
    padding: 28px 70px;
    gap: 27px;
    max-height: 400px;
    overflow: hidden;
    overflow-y: scroll;
}

.customHr {
    border-top: 1px;
    margin: 0;
}

.gunsCardContainer {
    margin-top: 30px;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    gap: 15px;
    align-items: stretch;
}


@media (max-width: 900px) {
    .container {
        padding: 30px 15px;
    }
    .days {
        width: 80%;
    }
    .associatesCardsModalContainer{
        padding: 28px 12px;
    }
    .buttonsContainer {
        flex-direction: column-reverse;
        justify-content: center;
        align-items: center;
    }
}