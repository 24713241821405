.input {
	width: 100%;
	flex-grow: 1;
	border: transparent;
	height: 40px;
	border-radius: 5px;
	background-color: #F9F9F9;
	outline: none;
	padding: 13px 10px;
	font-size: 14px;
	border: none;
}
.input fieldset{
	border: none;
}
.input > div{
	height: 100%;
}
.input input{
	height: 100%;
	padding: 0px 14px;
}