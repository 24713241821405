.sectionsContainer {
    display: flex;
    justify-content: space-between;
    flex-flow: row wrap;
    gap: 14px;
}

.section {
    background-color: #fff;
    border-radius: 9px;
    padding: 24px;
    display: flex;
    flex-direction: column;
    gap: 22px;
}

.section:hover {
    cursor: pointer;
}

.selected {
    color: white;
}