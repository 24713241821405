.container {
    width: 100px;
    height: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    background-color: #3B75FF1C;
    border: 1px dashed;
    text-align: center;
}

.container:hover{
    cursor: pointer;
}

.container .buttonText {
    font-size: 12px;
}