.boxPaper {
    padding: 10px;
    width: 100%;
    height: auto;
}

.boxAvatar {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 50px 0 50px 0;
}

.avatarBackground {
    width: 115px !important;
    height: 115px !important;
    background-color: #2AA8E0 !important;
}

.avatarBackground svg {
    width: 90%;
    height: 90%;
    color: white;
    fill: white;

}

.marginContent {
    margin-top: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.infoCard {
    margin-top: 15px;
}
.cardCharger{
    padding-bottom: 10px;
}