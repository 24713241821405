.mainContainer{
    display: flex;
    justify-content: space-between;
    height: 90vh;
}

.themeCard {
    height: auto;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    gap: 50px;
    background-color: white;
    padding: 34px 47px;
    width: 48%;
    height: 100%;
}

.addThemeCard {
    height: auto;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    gap: 50px;
    background-color: white;
    padding: 34px 47px;
    width: 48%;
    height: fit-content;
}

.title{
    color: #0D0D0D;
    font-size: 22px;
    font-weight: bold;
}

.iconRow{
    display: flex;
    gap: 20px;
    align-items: center;
}

.colorsFormContainer{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 30px;
}

.colorsContainer{
    display: flex;
    justify-content: center;
    gap: 50px;
    width: 100%;
}

.colorsFormContainer h3 {
    margin-bottom: 0;
}

.colorInputContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.inputColor {
    width: 75px;
    height: 50px;
    border: none; 
    background-color: #ffffff; 
    color: #000000; 
    padding: 5px;
}

.buttonsContainer {
    display: flex;
    flex-direction: row;
    gap: 20px;
    margin-left: 5%;
}

.modalContainer {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.modalButtonsContainer{
    display: flex;
    flex-direction: row;
    gap: 20px;
    justify-content: flex-end;
}

.themesContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 40px;
    height: 100%;
    overflow-y: scroll;
}

@media (max-width: 900px) {
    .mainContainer {
        flex-direction: column;
        height: 85vh;
    }
    .themeCard {
        width: 100%;
        padding: 17px 20px;
    }
    .iconRow{
        width: 100%;
        flex-direction: column;
        gap: 30px;
        justify-content: start;
        align-items: start;
    }
    .addThemeCard {
        margin-top: 30px;
        width: 100%;
        padding: 17px 20px;
    }
    .colorsContainer {
        gap: 0;
        justify-content: space-between;
    }
    .buttonsContainer {
        margin: 0;
    }
}