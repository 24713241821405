.container {
	width: auto;
	height: auto;
}

label input[type="file"] {
    display: none;
}

label:hover{
    cursor: pointer;
}


.image {
	width: 200px;
	height: 200px;
}