.mainContainer {
    display: flex;
    flex-direction: column;
    gap: 20px; 
 }
 
.paperContainer{
    display: flex;
    flex-direction: column;
    gap: 28px;
    background-color: #fff;
    border-radius: 8px;
}
 
.paperContainer h3 {
    margin: 0;
    padding-left: 50px;
    padding-top: 28px;
}

.paperContainer hr {
    margin: 0;
    border-top: 1px solid #E2E2E2;
}

.graphContainer {
    display: flex;
    justify-content: center;
    padding: 20px;
    height: 50vh;
}

.graph{
    height: 100%;
}

.noRevenue{
   font-size: 20px;
   font-weight: bold;
   height: fit-content;
}

.periodSelectorContainer {
    width: 100%;
    display: flex;
    justify-content: center;
}