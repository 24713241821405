.mainContainer{
    width: 100%;
    display: flex;
    gap: 25px;
}

.boxPaper {
    padding: 26px 0;
    height: auto;
}
