.columnsContainer {
    display: flex;
    flex-direction: column;
    height: fit-content;
    justify-content: space-around;
    align-self: center;
    width: 100%;
    gap: 20px;
    padding: 10px;
}

.column{
    display: flex;
    width: 100%;
    flex-direction: column; 
    gap: 10px;
    
}

.columnTitle{
    font-size: 16px;
    font-weight: bold;
}

.availableColumns {
    display: flex;
    flex-flow: row wrap;
    gap: 15px;
    width: 100%;
    justify-content: center;
}

.selectedColumns {
    display: flex;
    flex-flow: row wrap;
    gap: 15px;
    width: 100%;
    justify-content: center;
}

.columnHeader {
    display: flex;
    justify-content: space-between;
}
