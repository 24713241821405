.modalContainer {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.modalContainer > span {
    font-size: 18px;
}

.buttonsContainer {
    display: flex;
    gap: 15px;
    justify-content: flex-end;
}