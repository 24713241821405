.subContainer {
	width: 100%;
	height: auto;
}
.mainContainerImgs {
	background-image: url('../../assets/imgs/background.png');
	background-size: cover;
	height: 100vh;
}
.mainContainerForm {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 50px;
}
.subContainerForm {
    display: flex;
	flex-direction: column;
	align-items: center;
	flex: 1;
}
.subContainerFormInfo {
    display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 60%;
	height: 100%;
}
.title {
	font-weight: 800;
	font-size: 30px;
	color: var(--color-primary-main);
}
.txtResend{
	cursor: pointer;
	color: #16688d;
}
.containerTitle {
	width: 100%;
	display: flex;
	align-items: flex-end;
	justify-content: space-between;
	height: auto;
	margin-bottom: 50px;
}
.imgLogo {
	width: 150px;
}
.containerForgotPass {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: flex-end;
	margin-top: 12px;
	margin-bottom: 28px;
}
.labelForgotPass {
	color: #122A4B;
	text-decoration: none;
    letter-spacing: 0;
}

.enterCodeTxt{
	font-size: 18px;
	color: #9C9C9C;
	margin-bottom: 22px;
	font-weight: 500;
}

.enterCodeTxt a {
	text-decoration: none;
	color: #16688D;
}

.mailTxt{
	font-size: 20px;
	font-weight: bold;
	margin-top: 22px;
	margin-bottom: 39px;
}

.tapCodeTxt{
	font-size: 16px;
	font-weight: bold;
}

@media (max-width: 900px) {
	.mainContainerImgs {
		display: none;
	}
	.subContainerFormInfo {
		width: 90%;
		height: auto;
	}
	.subContainerForm {
		padding: 0px;
		margin-top: 5px;
	}
	.mainContainerForm {
		padding: 10px;
	}
}
