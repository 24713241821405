.container{
    margin: 20px 0 0 0;
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 8px;
}


.item{
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 0px;
	gap: 9px;
}