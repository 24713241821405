.map {
    width: 100%;
	max-width: 50vw;
    height: 100%;
	max-height: 50vh;
}

@media (max-width: 900px) {
    .map {
        max-width: 80vw;
    }
}