.label{
    color: #3F4254;
    font-size: 14px;
    font-weight: 600;
    padding: 0;
}

.labelDisabled {
    color: rgba(128, 128, 128, 0.9);
    font-size: 14px;
    font-weight: 600;
    padding: 0;
}

.labelDisabled > span {
    color: rgba(128, 128, 128, 0.9);
}


.label > span {
    color: #2BA8E0;
}

@media (max-width:641px)  {
    .label{
        font-size: var(--txt-size-Small);
        line-height: 20px;
    }
}