.boxPaper {
    padding: 10px;
    height: auto;
    width: 80%;

}

.branding {
    position: relative;
    width: 100px;
    height: 100px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 14px;
    cursor: pointer;
}

.branding svg {
    font-size: 70px;
    color: #E3E3E3;

}

.floatIcon {
    position: absolute;
    width: 25px;
    height: 25px;
    top: -10px;
    right: -10px;
    border-radius: 15px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
}

.floatIcon svg {
    font-size: 17px !important;
    color: var(--color-secondary-main);
}

.brandingText {
    width: 100px;
    margin-left: 14px;
    margin-top: 5px;
}

@media (max-width: 900px) {
    .boxPaper {
        width: 100%;

    }
}