.card{
    display: flex;
    height: fit-content;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 9px;
    padding: 15px;
    width: 48%;
}

.name{
    text-align: center;
    font-size: 20px;
}

.total{
    font-size: 22px;
    font-weight: bold;
}

@media (max-width: 600px) {
    .card {
        width: 100%;
    }
}