.boxPaper {
    padding: 10px;
    width: 100%;
    height: auto;
}

.buttonsContainer{
    display: flex;
    justify-content: space-between;
    width: 100%;
}