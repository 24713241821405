.boxPaper {
    padding: 26px 0;
    height: auto;
}

.separator{
    height: 1px;
    background-color: #E2E2E2;
    width: 100%;
}

.titleContainer{
    padding-left: 34px;
}

.titleContainer span{
    color: #002739;
    font-weight: 600;
    font-size: 27px;
}

.inputsContainer{
    width: 100%;
    display: flex;
    justify-content: space-around;
    padding-left: 20px;
    padding-right: 20px;
}

.row{
    width: 35%;
    display: flex;
    flex-direction: column;
    gap: 28px;
}

.buttonsContainer{
    margin-right: 65px !important;
    width: fit-content;
    display: flex;
    gap: 15px;
    align-self: flex-end;
}

@media (max-width: 600px) {
	.inputsContainer{
        padding: 0;
    }
}