.mainContainer {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.colorsContainer {
    display: flex;
    gap: 20px;
}

.title {
    font-size: 20px;
}

.color {
    width: 30px;
    height: 30px;
}