.filter-container {
	display: flex;
	justify-content: space-between;
}

.filter-container > button {
	max-width: 150px;
}

.filter-container > :first-child {
	display: flex;
}

.gunsContainer {
	display: flex;
	width: 100%;
	gap: 15px;
}

.removeGunBox {
	border-radius: 5px;
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 200px;
	height: 90px;
	padding: 20px;
	font-size: .90em;
	justify-content: center;
	text-align: center;
	font-weight: 600;
	gap: 10px;
}



.remove-gun-icon {
	font-size: xx-small !important;
  position: absolute;
  right: 3px;
  top: 3px;
}

.add-dashed-box {
	background: #3B75FF1C;
	border: 1px #1627F2 dashed;
	border-radius: 5px;
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 60px;
	height: 60px;
	font-size: 0.6em;
	justify-content: center;
	color: #1627F2;
	margin-left: 15px;
	margin-top: 10px;
}

.add-dashed-box:hover {
	cursor: pointer;
	background: #3b75ff3b;
}

.input-color {
	width: 24px;
	padding: 0px;
	border-style: none;
	background: #613d3c00
}

.branding-badge {
	width: 30px;
	height: 30px;
	border-radius: 15px;
	background: white;
	cursor: pointer;
	box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px;
}

.actions-container {
	display: flex;
	justify-content: space-between;
}

.actions-container>*{
	height: 30px;
	width: 30px;
	padding: 0px;
}

@media (max-width:641px) {
	.filter-container {
		flex-direction: column;
	}
		.filter-container>button {
			margin-top: 15px
		}
}