.stationsChargersContainer {
    background-color: #fff;
    border-radius: 8px;
    max-height: 600px;
}

.nameContainer {
    padding: 25px;
}

.customHr {
    border-top: 10px;
}

.chargersContainer {
    padding: 23px;
    display: flex;
    flex-flow: row wrap;
    gap: 8px;
    max-height: 300px;
    overflow-y: auto;
}

.chargerCard {
    width: 200px;
    height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px solid;
    border-radius: 9px;
    padding: 10px;
    gap: 10px;
}

.chargerCard > span {
    font-size: 15px;
    text-align: center;
}

.buttonsContainer{
    padding: 18px 65px;
    display: flex;
    width: 100%;
    justify-content: flex-end;
    gap: 15px;
}