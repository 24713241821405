:root {
	--color-primary-main: #155cfc;
	--color-primary-dark: #104bd6;
	--color-primary-light: #e5edff;
	--color-secondary-main: #2BA8E0;
	--color-secondary-dark: #1d7ad6;
	--color-blue-background: #dfedff;
	--color-grey-main: #cbcbcb;
	--color-green-main: #3abe21;
	--color-green-light: #d0f0c9;
	--color-green-dark: #08812c;
	--color-yellow-main: #e35700;
	--color-yellow-light: #fae295;
	--color-yellow-dark: #e35700;
	--color-error-main: #dd0528;
	--color-error-light: #ffe2e5;
	--color-error-dark: #e21b1b;
	--color-info-main: #293990;
	--color-info-light: #cbe4ff;
	--color-info-dark: #283990;
	--color-brown-main: #a08a7f;
	--color-brown-light: #ede6e2;
	--color-green-card-main: rgb(0, 174, 116);
	--color-red-card-main: rgb(168, 7, 53);
	--color-green-card-dark: rgb(85, 105, 88);
	--color-green-card-ligth: #6dd0b9;
	--color-yellow-card-main: #f0b366;
	--color-yellow-card-dark: #90701c;
	--color-black-main: rgb(29, 28, 40);
	--color-blue-main: #002739;
	--color-gray-main: #7F8297;
}
