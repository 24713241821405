.selectedAssociatesRow {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 50px;
}

.selectedAssociatesForm {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.optionsContainer {
    width: 80%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 10px;
}

.optionRow {
    display: flex;
    align-items: center;
    width: auto;
    gap: 20px;
}

@media (max-width: 900px) {
    .selectedAssociatesRow {
        padding-left: 15px;
        padding-right: 15px;
        flex-direction: column;
        gap: 20px;
    }
    .selectedAssociatesForm{
        flex-direction: column;
        gap: 20px;
    }
}