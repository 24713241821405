.gridColumn{
    display: flex;
    flex-direction: column;
    gap: 25px;
}

.sectionsContainer {
    display: flex;
    justify-content: space-between;
    flex-flow: row wrap;
    gap: 18px;
}

.section {
    width: 48%;
    background-color: #fff;
    border-radius: 9px;
    padding: 24px;
    display: flex;
    flex-direction: column;
    gap: 22px;
}

.section:hover {
    cursor: pointer;
}
