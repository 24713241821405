.mainContainer {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 15px;
    background-color: #fff;
    border-radius: 9px;
    box-shadow: 0px 7px 17px #0000000D;
    padding: 30px 0;
    margin-bottom: 20px;
}

.imgContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 53px;
    height: 53px;
    background-color: rgba(22, 39, 242, .1);
    border-radius: 50%;
}

.name {
    font-size: 16px;
    font-weight: bold;
}

.address {
    font-size: 14px;
    text-align: center;
}