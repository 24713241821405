/* The container */
.container {
	display: block;
	position: relative;
	padding-left: 35px;
	cursor: pointer;
	font-size: 22px;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	color: 000;
	font-size: 18px;
	font-weight: 500;
  }
  
  /* Hide the browser's default radio button */
  .container input {
	position: absolute;
	opacity: 0;
	cursor: pointer;
  }
  
  /* Create a custom radio button */
  .checkmark {
	position: absolute;
	top: 0;
	left: 0;
	height: 26px;
	width: 26px;
	background-color: #eee;
	border-radius: 50%;
  }
  
  /* On mouse-over, add a grey background color */
  .container:hover input ~ .checkmark {
	background-color: #eee;
  }
  
  /* When the radio button is checked, add a blue background */
  .container input:checked ~ .checkmark {
	background-color: #eee;
  }
  
  /* Create the indicator (the dot/circle - hidden when not checked) */
  .checkmark:after {
	content: "";
	position: absolute;
	display: none;
  }
  
  /* Show the indicator (dot/circle) when checked */
  .container input:checked ~ .checkmark:after {
	display: block;
  }
  
  /* Style the indicator (dot/circle) */
  .container .checkmark:after {
	top: 6px;
	left: 6px;
	width: 14px;
	height: 14px;
	border-radius: 50%;
	background: #2BA8E0;
  }