.mainContainer {
    display: flex;
    flex-direction: column;
    height: fit-content;
    justify-content: space-between;
    align-self: center;
    width: 100%;
    gap: 25px;
    padding: 10px;
}

.row {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    gap: 20px;
    height: fit-content;
}

.rowRadio {
    margin: 20px 0;
    display: flex;
    gap: 20px;
}

.rowRadio > span {
    font-size: 18px;
}

@media (max-width: 900px) {
	.row {
		flex-direction: column;
	}
}

@media (max-width: 600px) {
	.rowRadio {
		flex-direction: column;
	}
}