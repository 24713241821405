.item {
    width: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid;
    padding: 10px 15px;
    border-radius: 4px;
    gap: 15px;
    transition: all 0.2s linear;
}

.item:hover {
    cursor: pointer;
    transform: scale(1.1);
}

.selectedItem {
    width: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid;
    padding: 10px 15px;
    border-radius: 4px;
    gap: 15px;
    cursor: default;
}