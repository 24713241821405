.mainContainer{
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
}

.dataSection {
    display: flex;
    flex-direction: column;
    gap: 50px;
    background-color: white;
    border-radius: 9px;
    padding: 15px;
    width: 40%;
}

.dataSection h3 {
    margin: 0;
    margin-bottom: 10px;
}

.inputsContainer {
    display: flex;
    gap: 20px;
}

.cardsContainer{
	height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
}

.graphContainer{
    width: 58%;
    padding: 15px;
    display: flex;
	flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: white;
    border-radius: 9px;
	box-shadow: 0px 3px 34px #4E4E4E05;
}

.headerCard svg {
    color: #9EA0B6;
}

.title {
	font-size: 20px;
}

.containerSelectloaction{
	width: 100%;
	max-width: 800px;
	margin: 0 auto;
	background-color: white;
	box-shadow: 0px 3px 34px #4E4E4E05;
	border-radius: 5px;
}
.containerSelect{
	width: 100%;
	max-width: 300px;
	margin: 0 auto;
	padding: 45px 20px;
	text-align: center;
}
.iconLocation{
	font-size: 87px;
}
.cardContainer{
	display: flex;
	justify-content: space-between;
	background-color: white;
	box-shadow: 0px 3px 34px #4E4E4E05;
	border-radius: 5px;
	padding: 15px;
}
.containerCards{
	display: flex;
	flex-wrap: wrap;
	gap: 20px;
	justify-content: center;
}
.stationHeader{
	display: flex;
	align-items: flex-end;
}
.stationHeaderIcon{
	width: 114px;
	height: 114px;
	border: 1px solid #E8E8E8;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 13px;
	color: #005C53;
}
.stationHeaderIcon svg{
	font-size: 40px;
}
.stationHeaderInfo{
	width: 100%;
	max-width: 400px;
	padding-left: 20px;
}
.infoRow {
	display: flex;
	width: 100%;
	gap: 10px;
}
.infoElement {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	border: 1px dashed #b2b3b8;
	padding: 5px;
	width: 25%;
}
.infoNumber {
	display: flex;
	align-items: center;
	font-weight: bold;
	font-size: 20px;
}
.infoNumber svg{
	font-size: 14px;
	margin-right: 4px;
}
.infoName {
	font-size: 13px;
	text-align: center;
}
.stationTabs{
	display: flex;
	border-top: 1px solid #E2E2E2;
	margin-top: 20px;
}
.stationTabsItem{
	height: 45px;
	display: flex;
	padding: 0 25px;
	justify-content: center;
	align-items: flex-end;
	border-top: 2px solid transparent;
	cursor: pointer;
}
.stationTabsItemActive{
	height: 45px;
	display: flex;
	padding: 0 25px;
	justify-content: center;
	align-items: flex-end;
	border-top: 2px solid #005C53;
	color: #005C53;
	cursor: pointer;
}

.mapContainer {
	display: flex;
	flex-direction: row;
	gap: 15px;
}

@media (max-width: 900px) {
    .mainContainer{
        gap: 40px;
    }
    .dataSection{
        width: 100%;
    }
    .graphContainer {
        width: 100%;
        height: 50vh;
        padding: 0;
    }
    .inputsContainer {
        flex-direction: column;
    }
	.mapContainer {
		flex-direction: column;
	}
}

@media (max-width: 600px) {
	.stationTabsItem, .stationTabsItemActive {
		padding: 0 10px;
		align-items: center;
		padding-top: 10px;
	}
	.stationTabsItem span, .stationTabsItemActive span, .stationTabsItem svg,
	.stationTabsItemActive svg{
		font-size: 14px;
	}
	.cardsContainer{
		flex-direction: column;
		gap: 20px;
	}
}