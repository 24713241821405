.pageContainer {
    display: flex;
    flex-direction: column;
    gap: 150px
}

.mainContainer {
    padding: 20px;
    background-color: white;
    border-radius: 8px;
}

.customH3 {
    margin: 0;
}

.imageContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 15px;
}

.labelImage{
    font-size: 18px;
}
