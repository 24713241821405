.mainContainer {
    background-color: white;
    border-radius: 8px;
    padding: 20px;
    display: flex;
    flex-direction: column;
}

.row {
    display: flex;
    gap: 20px;
    justify-content: space-around;
}

.item{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 15px;
}

.item > span {
    font-size: large;
    font-weight: 600;
}

.colorsContainer{
    display: flex;
    gap: 25px;
}

.colorContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}