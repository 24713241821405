.container {
    width: 400px;
    height: auto;
    border-radius: 5px;
    padding: 20px 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 15px;
    margin-bottom: 20px;
}

.imgContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 53px;
    height: 53px;
    border-radius: 50%;
}

.name {
    font-size: 14px;
    font-weight: bold;
}

.infoRow {
    display: flex;
    justify-content: space-around;
    width: 100%;
}

.infoElement{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px dashed;
    padding: 5px;
    width: 25%;
}

.infoNumber {
    font-weight: bold;
    font-size: 20px;
}

.infoName {
    font-size: 13px;
}


