.paperGrid {
    padding: 50px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: center;
}

.avatarBackground {
    width: 115px !important;
    height: 115px !important;
    background-color: var(--color-secondary-main) !important;
}

.infoCard {
    margin-top: 15px;
}

.cardCharger {
    padding-bottom: 10px;
}

.plugType {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 7px;
}

.avatarBackground2 {
    width: 135px !important;
    height: 135px !important;

}

.avatarBackground svg,
.avatarBackground2 svg {
    width: 60%;
    height: 60%;
}