.mainContainer {
   display: flex;
   flex-direction: column;
   gap: 20px; 
}

.paperContainer{
    display: flex;
    flex-direction: column;
    gap: 28px;
    background-color: #fff;
    border-radius: 8px;
    padding-bottom: 30px;
}

.paperContainer h3 {
    margin: 0;
    padding-left: 50px;
    padding-top: 28px;
}

.paperContainer > hr {
    margin: 0;
    border-top: 1px solid #E2E2E2;
}

.hr{
    border-color: rgba(0,0,0,.2);
}

.buttonsContainer{
    margin-top: -28px;
    padding: 18px 65px;
    display: flex;
    width: 100%;
    justify-content: flex-end;
    gap: 15px;
}

.ownersContainer {
    display: flex;
    flex-direction: column;
    padding: 0 30px;
    gap: 10px;
}