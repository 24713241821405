.mainContainer{
	width: 100%;
	height: auto;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 15px;
	background-color: #fff;
	border-radius: 9px;
	box-shadow: 0px 3px 34px #4E4E4E05;
	padding: 30px 0;
	margin-bottom: 20px;
}
.imgContainer{
	display: flex;
	justify-content: center;
	align-items: center;
	width: 53px;
	height: 53px;
	background-color: rgba(0, 92, 83, 0.15);
	border-radius: 50%;
}
.name{
	font-size: 16px;
	font-weight: bold;
}
.address{
	font-size: 14px;
	text-align: center;
	width: 100%;
	max-width: 250px;
	margin: 0 auto;
}
.infoRow{
	display: flex;
	justify-content: center;
	width: 100%;
	gap: 10px;
}
.infoElement {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	border: 1px dashed;
	padding: 5px;
	width: 25%;
}
.infoNumber{
	font-weight: bold;
	font-size: 20px;
	color: #005C53;
}
.infoName{
	font-size: 13px;
}