.content {
    width: 100%;
    position: relative;
}

.contentDisabled > label,
.contentDisabled > span {
    color: rgba(128, 128, 128, 0.9);
}

.contentDisabled > .inputContent > input {
    opacity: 0.8;
}

.inputContent > input {
    width: 100%;
    flex-grow: 1;
    border: 1px solid rgba(128, 128, 128, 0.5);
    height: 43px;
    border-radius: 5px;
    background-color: #F9F9F9;
    outline: none;
    padding: 13px 10px;
    font-size: 14px;
}

.inputContent > input:focus {
    background-color: rgba(128, 128, 128, 0.2);
    font-size: 14px;
}

.inputContent > input::placeholder {
    color: #8b8b8b;
}

/* Estilos específicos para el textarea */
.textarea {
    min-height: 100px;
    width: 100%;
    resize: vertical;
    background-color: #f9f9f9;
    padding: 13px 10px;
    border-radius: 5px;
    flex-grow: 1;
    border: 1px solid rgba(128, 128, 128, 0.5);
    font-size: 14px;
}

/* Si necesitas sobrescribir algunos estilos del input para el textarea */
.textarea:focus {
    outline: none;
    background-color: rgba(128, 128, 128, 0.3);
}

/* Estilo para cuando el cursor está sobre el textarea */
.textarea:hover:not(:disabled) {
    border-color: #333;
}

/* Estilo para cuando el textarea está desactivado */
.textarea:disabled {
    color: rgba(128, 128, 128, 0.9);
    cursor: not-allowed;
}

@media (max-width: 641px) {

    .textarea {
        font-size: var(--txt-size-XSmall);
    }

    .input {
        font-size: var(--txt-size-XSmall);
        height: 40px;
        line-height: 18px;
    }

}
