.cardContainer {
    width: 298px;
    height: auto;
    background-color: #F5F5F5;
    border: 1px solid #E6E6E6;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 15px;
    border-radius: 8px;
}

.cardContainer:hover {
 cursor: pointer;
}

.rowName {
    display: flex;
    gap: 15px;
    margin-bottom: 19px;
    align-items: center;
}

.rowName span {
    font-size: 16px;
    font-weight: bold;
}

.rfc {
    font-size: 16px;
    font-weight: bold;
}

.address {
    font-size: 14px;
    font-weight: bold;
}