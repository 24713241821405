.mainContainer{
    height: fit-content;
    background-color: #fff;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 20px 0;
}

.mainContainer h3 {
    padding-left: 30px;
    margin: 0;
}