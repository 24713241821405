.card{
	width: 100%;
	max-width: 300px;
	min-height: 170px;
	border: 1px solid #E6E6E6;
	background-color: #F5F5F5;
	box-shadow: 0px 3px 34px #4E4E4E05;
	border-radius: 5px;
	font-size: 15px;
	padding: 15px;
	display: flex;
	flex-direction: column;
}
.title{
	font-size: 19px;
	font-weight: 700;
	margin-top: 0px;
	display: flex;
	align-items: center;
	gap: 10px;
	margin-left: -5px;
}
.text{
	margin: 0;
	display: flex;
	align-items: center;
}
.textGreen{
	color: #005C53;
	margin: 0;
	font-weight: 600;
}
.circle{
	display: block;
	width: 10px;
	height: 10px;
	border-radius: 50%;
	margin-right: 5px;
}