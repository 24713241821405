.title{
    color: #0D0D0D;
    font-size: 18px;
    font-weight: bold;
}

.langCard {
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    gap: 50px;
    background-color: white;
    padding: 34px 47px;
}
