.container { 
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 32px;
}

.cardsContainer {
    display: flex;
    flex-flow: row wrap;
    gap: 20px;
}