.mainContainer {
    padding: 20px;
    background-color: white;
    border-radius: 8px;
}

.headerContainer {
    margin-bottom: 25px;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}

.inputsContainer {
    max-width: 40%;
    display: flex;
    gap: 5px;
    align-items: flex-end;
}

@media (max-width: 600px) {
	.headerContainer {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 20px;
    }
}