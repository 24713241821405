.filter-container {
	display: flex;
	justify-content: space-between;
}

.filter-container>button {
	max-width: 150px;
}

.filter-container> :first-child {
	display: flex;
}

.footer-buttons-container {
	justify-content: end;
}

.footer-buttons-container > button {
	max-width: 150px;
}

.cardContent{
	display: flex;
	flex-direction: column;
	gap: 5px;
}

/* .card {
	transition: 0.2s;
}

.card:hover{
	transform: scale(1.05);
} */
.inputsContainer, .inputsContainerFull {
	flex-wrap: nowrap;
}
@media(max-width: 1020px) {
	.inputsContainer {
		flex-wrap: wrap;
	}
}

@media(max-width: 720px) {
	.inputsContainerFull {
		flex-wrap: wrap;
	}
}