.cardContainer {
    width: 298px;
    height: auto;
    background-color: #F5F5F5;
    border: 1px solid #E6E6E6;
    display: flex;
    flex-direction: column;
    padding: 15px;
    border-radius: 8px;
    transition: all 0.2s ease;
}

.cardContainer:hover {
 cursor: pointer;
 transform: scale(1.05);
}

.rowName {
    display: flex;
    gap: 15px;
    margin-bottom: 19px;
    align-items: center;
}

.rowName span {
    font-size: 16px;
    font-weight: bold;
}

.rfc {
    font-size: 15px;
}

.address {
    font-size: 14px;
    font-weight: bold;
}

.costFactorContainer{
    display: flex;
    flex-direction: column;
}

.titleCost {
    font-size: 13px;
    font-weight: bold;
}

.valueCost {
    font-size: 13px;
}