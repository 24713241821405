.content {
	display: flex;
	flex-direction: column;
}

.input {
  border: transparent;
  height: 35px;
  border-radius: 5px;
  background-color: #f3f3f3;
  outline: none;
  padding: 1px 5px;
}