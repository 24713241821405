.mainContainer{
    display: flex;
    flex-direction: column;
    gap: 35px;
    padding-top: 31px;
}

.formContainer {
    background: #FFFFFF;
    box-shadow: 0px 3px 34px #4E4E4E05;
    height: auto;
}

.inputsContainer {
    display: flex;
    justify-content: space-between;
}

.column {
    width:49%;
	display: flex;
	flex-direction: column;
	gap: 20px;
}

.customHr {
    border-top: 1px;
    margin: 0;
}


.titleContainer{
    padding: 28px 34px;
}

.title {
    color: var(--color-blue-main);
    font-size: 18px;
    font-weight: 600;
}

.associatesContainer {
    display: flex;
    flex-direction: column;
    padding: 30px 65px;
    gap: 28px;
}

.buttonsContainer{
    padding: 18px 65px;
    display: flex;
    width: 100%;
    justify-content: flex-end;
    gap: 15px;
}

.associatesCardsContainer {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    gap: 15px;
}

.associatesCardsModalContainer {
    display: flex;
    flex-flow: row wrap;
    padding: 28px 70px;
    gap: 27px;
}

@media (max-width: 900px) {
    .associatesContainer {
        padding: 30px 15px;
    }
    .associatesCardsContainer{
        gap: 30px;
    }
	.buttonsContainer{
        flex-direction: column;
    }
    .inputsContainer{
        flex-direction: column;
        gap: 20px;
    }
    .column {
        width: 100%;
    }
    .selectedAssociatesRow {
        padding-left: 15px;
        padding-right: 15px;
        flex-direction: column;
        gap: 20px;
    }
    .selectedAssociatesForm{
        flex-direction: column;
        gap: 20px;
    }
}