.menuDrawer,
.submenuDrawer {
	display: block;
	width: 100%;
	list-style: none;
	margin: 0px;
	padding: 0px;
	user-select: none;
	transition: all 0.3s ease;
}

.logout{
	display: flex;
	justify-content: center;
	margin: auto auto 20px auto !important;
	align-self: flex-end;
	justify-self: flex-end;
}

.logout:hover{
	cursor: pointer;
}

@keyframes slideInRight {
	from {
	  transform: translateX(-100%);
	}
	to {
	  transform: translateX(0);
	}
}

@keyframes slideInLeft{
	from {
	  transform: translateX(0);
	}
	to {
	  transform: translateX(-100%);
	}
}

  .slideRight {
	animation: slideInRight .08s ease-out forwards;
  }
  
  .slideLeft {
	animation: slideInLeft 10s reverse forwards;
  }

.menuDrawer>li>div {
	display: flex;
	align-items: center;
	padding-top: 7px;
	border-radius: 10px;
	font-size: 14px;
}

.menuDrawer>li>div>svg:first-child {
	margin-right: 15px !important;
}

.menuDrawer>li>div[data-active='true'] {
	color: white;
}

.menuDrawer>li>div:hover {
	color: white;
}


.menuDrawer>li>div>span {
	flex-grow: 1;
}

.menuDrawer>li,
.submenuDrawer>li {
	cursor: pointer;
}

/* .menuDrawer > li:hover > div,
.menuDrawer > li > div[data-active='true'] {
	background: rgba(0, 0, 0, 0.8);
} */

.menuDrawer>li>ul>li {
	margin-left: 15px;
}

.submenuDrawer {
	height: 0px;
	overflow: hidden !important;
	margin: 10px 0px 10px 0px;
	padding: 0px;
	font-size: 16px;
	transform: scaleY(0);
	transform-origin: top;
	transition: transform 0.3s ease;
	max-width: 300px;
	width: 100%;
}

.submenuDrawerResponsive {
	height: 0px;
	overflow: hidden !important;
	margin: 10px 0px 10px 0px;
	padding: 0px;
	font-size: 16px;
	transform: scaleY(0);
	transform-origin: top;
	transition: transform 0.26s ease;
	max-width: 150px;
	width: 100%;
}

.submenuDrawer>li {
	padding: 7px;
	padding-top: 10px;
	padding-bottom: 10px;
	margin-bottom: 10px;
}

.submenuDrawer>li>span {
	padding-top: 10px;
	padding-bottom: 10px;
}

.submenuDrawer[data-active='true'] {
	height: auto;
	transform: scaleY(1);
}

.menuDrawer>li {
	margin: 20px 0px 20px 0px;
}

.arrowMaximus {
	position: absolute;
	right: -25px;
	top: 45px;
	width: 40px;
	height: 40px;
	background: #ffffff;
	color: gray;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 10px;
	z-index: 1300;
	box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
		rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
	border: 1px solid rgba(50, 50, 93, 0.25);
	cursor: pointer;
}

.menuDrawer[data-small='true']>li>div {
	padding: 10px 15px;
}

.menuDrawer[data-small='true']>li>div>span,
.menuDrawer[data-small='true']>li>ul {
	display: none;
}

.drawerSubMenu{
	display: flex;
	flex-direction: column;
	height: 100vh;
	width: 325px;
}

.subMenuHeader{
	width: 100%;
	height: 65px;
	padding: 16px;
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 21px;
}

.subMenuHeaderTxt{
	color: white;
	font-size: 16px;
}

.subMenuContent{
	display: flex;
	flex-direction: column;
	padding: 16px;
}

.subMenuContetnOptionsContainer{
	display: flex;
	flex-direction: column;
	gap: 40px;
	padding: 18px;
}

.subMenuContentTxt{
	color:white;
	font-size: 23px;
}
.none{
	display: block;
}
.hidden{
	display: none;
}
.showMobile{
	display: block;
}

@media (max-width: 900px) {
	.drawerSubMenu {
		position: fixed;
		z-index: 90000;
		top: 0px;
		left: 90px;
	}
}

@media (max-width: 600px) {
	.drawerSubMenu {
		width: 200px;
	}
	.subMenuContent{
		padding: 20px 5px;
	}
	.subMenuContetnOptionsContainer{
		padding: 10px;
		gap: 20px;
	}
}