.mainContainer {
    background-color: white;
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    padding: 20px;
    gap: 20px;
}

.mainContainer > h3 {
    margin: 0;
}

.mainContainer hr {
    margin: 0;
}

.txtSection {
    font-size: 18px;
    font-weight: bold;
}