.button{
    position: absolute;
    background: unset;
    border: unset;
    cursor: pointer;
    border-radius: 50%;
    height: 45px;
    width: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    right: 5px;
    transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    outline: none;
}
.button:hover{
    background-color: rgba(0, 0, 0, 0.04);
}
.button:active{
    background-color: rgba(0, 0, 0, 0.08);
}


@media (max-width:641px)  {
    .button {
        height: 40px;
        width: 40px;
    }
}