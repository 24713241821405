.VerificacionLogo {
    align-items: center;
    justify-content: center;
    display: flex;
    margin-bottom: 20px;
}

.VerificacionCodigoTitle {
    font-size: 35px;
    text-align: center;
    color: #293990;
    width: 100%;
    display: block;
    clear: both;
}

.VerificacionText {
    font-size: 17px;
    text-align: center;
    width: 100%;
}

.divCodigos {
    width: 100%;
    margin: 0 auto 35px;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    display: flex;
    align-items: center;
}

.VerificacionCodigo {
    width: 60px;
    background: #F3F6F9;
    border-radius: 9px;
    height: 60px;
    color: #000000;
    border-color: #E1E3EA;
    border-style: solid;
    border-width: 1px;
    display: flex;
    align-items: center;
    padding-left: 10px;
    font-size: 2em;
    text-align: center;
}

.VerificacionCodigoResponsive {
    width: 10%;
    background:#F3F6F9;
    border-radius: 9px;
    height: 40px;
    color: #000000;
    border-color: #E1E3EA;
    border-style: solid;
    border-width: 1px;
    display: flex;
    align-items: center;
    padding-left: 10px;
    font-size: 2em;
    text-align: center;
}

.textGray14 {
    font-size: 16px;
    color: #858585;
}

link  {
    text-decoration: underline;
    color: red;
    cursor: pointer;
}

link:hover {
    cursor: pointer;
}

.divboton {
    align-items: center;
    justify-content: center;
    display: flex;
    width: auto;
    padding-top: 5%;
    padding-left: 150px;
    padding-right: 150px;
}