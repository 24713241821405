.mainContainer{
    display: flex;
    flex-direction: row;
    gap: 20px;
    align-items: center;
}

.mainContainer:hover{
    cursor: pointer;
}

.subContainer{
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.title{
    color: white;
    font-weight: bolder;
    font-size: 15px;
}

.desc{
    font-size: 14px;
    color: white;
}