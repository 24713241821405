.mainContainer {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.paperContainer{
    background-color: #fff;
    height: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    border-radius: 8px;
}

.paperContainer > h2 {
    margin-left: 50px;
}

.paperContainer > hr {
    margin: 0;
    border-top: 1px solid #E2E2E2;
}

.formContainer{
    padding: 45px 65px;
}

.inputsContainer, .inputsContainerFull {
    display: flex;
    gap: 50px;
}
.inputsItem{
    width: 100%;
}

.chargerList {
    display: flex;
    flex-flow: row wrap;
    gap: 15px;
    align-items: center;
}

.buttonsContainer{
    padding: 18px 65px;
    display: flex;
    width: 100%;
    justify-content: flex-end;
    gap: 15px;
}

@media(max-width: 1020px){
    .inputsContainer {
        flex-wrap: wrap;
    }
}
@media(max-width: 720px) {
    .inputsContainerFull {
        flex-wrap: wrap;
    }
}