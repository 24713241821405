.container{
    background-color: white;
    border-radius: 4px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.container hr {
    border-top: 1px;
    margin: 0;
}

.permissionsContainer {
    display: flex;
    gap: 100px;
}

.columnPermission {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 20%;
}
