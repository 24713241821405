/* styles/variables-helper.module.css */

.container {
    margin-top: 16px;
    border: 1px solid var(--color-grey-main);
    border-radius: 4px;
    overflow: hidden;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 16px;
    background-color: #f9f9f9;
    font-weight: 500;
    cursor: pointer;
    user-select: none;
}

.header:hover {
    background-color: var(--color-grey-main);
}

.arrow {
    font-size: 12px;
    transition: transform 0.3s ease;
}

.arrowExpanded {
    transform: rotate(180deg);
}

.content {
    padding: 16px;
    background-color: white;
}

.description {
    color: #666;
    font-size: 14px;
    margin-bottom: 12px;
}

.variablesContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    margin-bottom: 16px;
}

.variableChip {
    display: inline-block;
    padding: 6px 12px;
    background-color: var(--color-primary-main);
    opacity: 0.8;
    border: 1px solid var(--color-primary-dark);
    border-radius: 16px;
    font-size: 14px;
    color: var(--color-primary-light);
    cursor: pointer;
    transition: background-color 0.2s;
}

.variableChip:hover {
    opacity: 1;
}

.modifierChip {
    display: inline-block;
    padding: 4px 10px;
    background-color: var(--color-green-light);
    border: 1px solid var(--color-green-dark);
    border-radius: 12px;
    font-size: 13px;
    color: var(--color-green-dark);
}

.divider {
    height: 1px;
    background-color: #e0e0e0;
    margin: 16px 0;
}

.subtitle {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 8px;
}

.examples {
    background-color: #f9f9f9;
    padding: 12px;
    border-radius: 4px;
    margin-top: 16px;
}

.examples code {
    font-family: monospace;
    font-size: 13px;
    line-height: 1.6;
}
