.title{
	font-size: 20px;
}
.header{
	display: flex;
	justify-content: space-between;
	align-items: center;
	border-bottom: 1px solid #E2E2E2;
	padding: 15px 30px;
}
.mb{
	margin-bottom: 30px;
}
.container{
	padding: 30px;
}

.buttonsContainer {
	display: flex;
	gap: 20px;
	justify-content: flex-end;
}

.downloadButtonsContainer {
	display: flex;
	justify-content: center;
	gap: 50px;
	margin-top: 30px;
	margin-bottom: 30px;
}