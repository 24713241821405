.buttonIconSmall {
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 20px 10px;
	max-width: 50px;
	width: auto;
	height: 30px;
	border-radius: 5px;
	font-size: 12px;
	font-weight: bold;
	flex-direction: column;
	cursor: pointer;
	border-radius: 12px;
}
.buttonIconSmall:active {
	opacity: 0.7;
}

