.cardContainer {
    width: 215px;
    height: auto;
    background-color: #F5F5F5;
    border: 1px solid #E6E6E6;
    display: flex;
    flex-direction: column;
    padding: 15px;
    border-radius: 8px;
    justify-content: center;
    align-items: center;
    gap: 8px;
}

.name {
    font-weight: bold;
    font-size: 16px;
}

.infoContainer {
    display: flex;
    flex-direction: column;
    font-size: 12px;
    justify-content: center;
    align-items: center;
}

.infoContainer > span:first-child {
    font-weight: bold;
}

.infoDesc {
    text-align: center;
}