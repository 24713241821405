.mainContainer {
    width: 100%;
    display: flex;
    flex-direction: column;    
}

input[type=time] {
    border: none;
    color: #2a2c2d;
    font-size: 14px;
    font-family: helvetica;
    background-color: #F9F9F9;
    height: 40px;
    border-radius: 5px;
    padding: 13px 8px;
  }
  
  /* Wrapper around the hour, minute, second, and am/pm fields as well as 
  the up and down buttons and the 'X' button */
  input[type=time]::-webkit-datetime-edit-fields-wrapper {
    display: flex;
  }
  
  /* The space between the fields - between hour and minute, the minute and 
  second, second and am/pm */
  input[type=time]::-webkit-datetime-edit-text {
    padding: 19px 4px;
  }
  
  /* The naming convention for the hour, minute, second, and am/pm field is
  `-webkit-datetime-edit-{field}-field` */
  
  /* Hour */
  input[type=time]::-webkit-datetime-edit-hour-field {
    border-radius: 15%;
    padding: 19px 0px;
  }
  
  /* Minute */
  input[type=time]::-webkit-datetime-edit-minute-field {
    border-radius: 15%;
    padding: 19px 0px;
  }
 
  /* Second */
  input[type=time]::-webkit-datetime-edit-second-field {
    display: none;
  }
  
  /* AM/PM */
  input[type=time]::-webkit-datetime-edit-ampm-field {
    border-radius: 15%;
    padding: 19px 0px;
  }
  
  /* 'X' button for resetting/clearing time */
  input[type=time]::-webkit-clear-button {
    display: none;
  }
  
  /* Up/Down arrows for incrementing/decrementing the value */
  input[type=time]::-webkit-inner-spin-button {
    display: none;
  }