.container {
    box-shadow: 0px 7px 17px #0000000D;
    border-radius: 9px;
    width: 268px;
    height: auto;
    padding: 15px;
    display: flex;
    flex-direction: column;
    gap: 22px;
    margin-bottom: 20px;
}

.rowName {
    display: flex;
    align-items: center;
    gap: 15px;
}

.name {
    font-size: 17px;
    font-weight: bold;
}

.rowInfo {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.chargers {
    font-size: 16px;
    font-weight: 600;
}

.address {
    display: flex;
    flex-direction: column;
    font-size: 16px;
}

.viewLink {
    margin-top: auto;
    font-size: 15px;
    font-weight: bold;
}

.viewLink:hover {
    cursor: pointer;
}