.mainContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    width: 20px;
    height: 20px;
    border-radius: 50px;
    border: 2px solid red;
    cursor: pointer;
    transition: 0.2s;
}

.button {
    width: 10px;
    height: 10px;
    border-radius: 50px;
    background-color: red;
}

.mainContainer:hover{
	transform: scale(1.2);
} 

