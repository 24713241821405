.container {
    display: flex;
    flex-direction: column;
    padding: 25px 10px;
    gap: 15px;
}

.container hr {
    margin: 0;
}

.title{
    font-weight: 600;
    font-size: 20px;
}

.subtitle{
    font-size: 20px;
}

.formContainer {
    width: 100%;
    background-color: red;
    display: flex;
    gap: 20px;
    align-items: center;
}

.formContainer > span {
    width: 28%;
}

.buttonsContainer {
    align-self: flex-end;
    display: flex;
    
    gap: 10px;
}

.onlineIndicatorContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
	height: 20px;
	border-radius: 20px;
    background-color: transparent;
    border: 2px solid;
}

.onlineIndicatorContent {
    width: 10px;
	height: 10px;
	border-radius: 50px;
}

.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

@media (max-width: 600px) {
	.container {
		padding: 0;
	}

    .buttonsContainer {
        flex-direction: column-reverse;
        align-self: center;
    }
}