.customModal {
    width: 50vw !important;
    height: 70vh !important;
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: space-between;
}

.boxIcon {
    height: 70%;
}

.avatarCheck {
    width: 90px !important;
    height: 90px !important;
    background-color: var(--color-secondary-main) !important;
}

.avatarCheck svg {
    font-size: 50px;
}

@media (max-width: 900px) {
    .customModal {
        width: 70vw !important;
        height: 50vh !important;

    }
}