.mainContainer {
    background-color: #fff;
    border-radius: 8px;
}

.titleContainer {
    padding: 24px 48px 24px 48px;
}

.titleContainer h3 {
    margin: 0;
}

.modalContent {
    padding: 45px;
    display: flex;
}

.infoContainer {
    display: flex;
    gap: 10px;
}

.infoName {
    font-size: 21px;
    color: #9C9FB5;
}

.infoPer {
    font-size: 21px;
    font-weight: 900;
}

.buttonsContainer{
    padding: 18px 65px;
    display: flex;
    width: 100%;
    justify-content: flex-end;
    gap: 15px;
}

@media only screen and (max-width: 900px) {
    .modalContent {
        padding: 20px;
        flex-direction: column;
    }
}