.mainContainer{
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    background-color: white;
    padding: 34px 47px;
}

.mainContainer h3 {
    margin: 0;
}


@media (max-width: 600px) {
	.mainContainer{
        padding: 34px 15px;
    }
}