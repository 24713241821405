.boxPaper {
    padding: 10px;
    width: 100%;
    height: auto;
}

.header {
    min-height: 125px;
}

.logoImg {
    height: 95px;
    width: 95px;
    align-self: center;
    justify-self: center;
    z-index: 100;
}

.flexText {
    display: flex;
    gap: 10px;
}

.iconBox {
    background-color: #DAF4E6;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    cursor: pointer;
    width: 90%;
    height: 40%;
}

.iconBox svg {
    color: #5DD092;
    fill: #5DD092;
    font-size: 1.8rem;

}

.switchBox {
    height: 40%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.flexContainer {
    display: flex;
    flex-direction: column;
    gap: 40px;
}

@media (max-width: 900px) {
    .iconBox {
        height: 90%;
    }
    .flexText {
        flex-direction: column;
        gap: 0px;
    }
}